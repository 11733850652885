// Get bottom nav input button element
let navBotBtn = document.querySelector('.js-nav-bot__btn');
// Get bottom nav input button element
let navCloseBtn = document.querySelector('.js-nav-close__btn');
// Accessibility
// Get all checkboxes with class 'nav-toggle-input'
let navCheckboxes = document.querySelectorAll('.nav-toggle-input');
// Get the body of the document
let navBodyToggle = document.body;

// Toggle fullscreen class
function toggleNavClasses() {
    navBodyToggle.classList.toggle('js-prevent-scroll');
    navBodyToggle.classList.toggle('js-nav-open');
}

// Initially check to see if the input button is checked
if (navBotBtn.checked) {
    // If checked then add the class to .navBodyToggle
    toggleNavClasses();
}

// Listen for a change to the checked input button
navBotBtn.addEventListener('change', function () {
    toggleNavClasses();
});

// Listen for a change to the checked input button
navCloseBtn.addEventListener('change', function () {
    toggleNavClasses();

    navCheckboxes.forEach(function (checkbox) {
        checkbox.checked = false;
        checkbox.removeAttribute('aria-expanded');
    });
});

// Get Bottom Navigation element
let navBot = document.querySelector('.js-nav-bot');

// Listen for a click outside of the Bottom Navigation
document.addEventListener('click', function(event) {
  let clickInsideMenu = navBot.contains(event.target);
    // If clicked outside of target element menu and body contains 'js-prevent-scroll'
    const classNames = ['js-nav-open', 'js-prevent-scroll']
    if (!clickInsideMenu && classNames.every(className => navBodyToggle.classList.contains(className))) {
        // Close navigation
        navBotBtn.checked = false;
        toggleNavClasses();
    }
});

// Toggle 'aria-expanded' for these checkboxes
if (navCheckboxes) {
    navCheckboxes.forEach(function (checkbox) {
        checkbox.addEventListener("change", function () {
            if (checkbox.checked) {
                checkbox.setAttribute('aria-expanded', 'true');
                document.querySelectorAll('.nav-list-sub').forEach(function (navList) {
                    navList.classList.remove("nav-visibility");
                });
                checkbox.parentElement.querySelector('.nav-list-sub').classList.add("nav-visibility");
            }
            else {
                checkbox.removeAttribute('aria-expanded');
                document.querySelectorAll('.nav-list-sub').forEach(function (navList) {
                    navList.classList.add("nav-visibility");
                });
            }
        }, false);
    });
};

// add show/hide toggle button for partner bullet list
let listWithToggleItems = document.querySelectorAll('.js-add-toggle-class');

if (listWithToggleItems) {
    listWithToggleItems.forEach(function (list) {
        let firstItem = list.querySelector("li:first-child");
        let firstItemContent = firstItem.textContent;
        firstItem.innerHTML = firstItemContent + '<span class="js-showhide__trigger"><span class="color-black">...</span>more</span>'

        let lastItem = list.querySelector("li:last-child");
        let lastItemContent = lastItem.textContent;
        lastItem.innerHTML = lastItemContent + '<span class="js-showhide__trigger">  less</span>'
    });
};

// Hide Mobile header on on scroll down
let didScroll;
let prevScrollPos = window.pageYOffset;
window.onscroll = function() {
    didScroll = true;
};

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function hasScrolled() {
    let currentScrollPos = window.pageYOffset;

    if (prevScrollPos > currentScrollPos) {
        navBot.classList.remove("nav-up");
    } else {
        navBot.classList.add("nav-up");
    }
    prevScrollPos = currentScrollPos;
}